<template>
  <div>

  </div>

</template>
<script>
import {getcurrentUser} from '@/api'
import store from '../../store'
export default{
  data(){
    return{

    }
  },
  created () {
    
    this.getToken()

},
mounted () {

},
methods: {
 async getToken(){
const res =await getcurrentUser()
if (res.code==0&&res.message==='success') {
  if (res.data.roles[0].id==1) {
    localStorage.setItem('areaCode','330000')    
    this.$router.push('/city')
  }else if(res.data.roles[0].id==2&&res.data.areaType==3){
    this.$router.push('/newDistrict')
  }else{
    localStorage.setItem('areaCode',res.data.area)    
    this.$router.push('/city')
  }
  const token =this.$route.query.token
    localStorage.setItem('token',token)
    store.dispatch('GET_TOKEN')  
}
}
}
}
</script>